import type {Ref, WatchCallback} from "vue"
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {breakpointsTailwind, useBreakpoints as useBreakpointsVU} from '@vueuse/core';

export function useDebugColorMode() {
    const route = useRoute();
    const colorMode = useColorMode();
    watch(() => route.query, () => {
        if (!route.query.color) return;
        colorMode.preference = route.query.color.toString();
    }, {immediate: true});
}

export const useBreakpoints = useBreakpointsVU(breakpointsTailwind);

export function useDetectHeading({root, onHeadingDetected}: {
    root: Ref<HTMLElement>
    onHeadingDetected?: WatchCallback<HTMLHeadingElement>
}) {
    let heading = ref<null | HTMLHeadingElement>(null);
    let observer = ref<null | IntersectionObserver>(null);

    onMounted(() => {
        observer.value = new IntersectionObserver((entries) => {
            const firstIntersection = entries.find((entry) => entry.isIntersecting);

            if (!firstIntersection || !firstIntersection.target) return;

            let current = firstIntersection.target;
            while (true) {
                if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(current.tagName)) {
                    heading.value = current as HTMLHeadingElement;
                    break;
                }

                if (!current.previousElementSibling) {
                    break;
                }

                current = current.previousElementSibling;
            }
        }, {rootMargin: "-20% 0px -70% 0px"});
    });

    onBeforeUnmount(() => observer.value?.disconnect());

    watch(root, (newRoot) => {
        observer.value?.disconnect();
        newRoot?.querySelectorAll('p').forEach((p) => observer.value?.observe(p));
    }, {immediate: true});

    if (onHeadingDetected) {
        watch(heading, (heading, oldValue, onCleanup) => {
            if (!heading) return;
            onHeadingDetected(heading, oldValue, onCleanup);
        }, {immediate: true});
    }

    return {heading, observer};
}

